.header {
  padding: 10px;
  background-color: rgba(69, 220, 255, 1);
}

.container {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 20px;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name {
  margin: 0;
}

.name a {
  display: flex;
  align-items: center;
  font-size: 28px;
  color: var(--accent-primary);
  font-weight: 700;
  text-decoration: none;
}

.nav {
  padding-left: 20px;
}

.link {
  display: inline-block;
  padding: 0 10px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: var(--text-color-primary);

  &.active {
    color: var(--accent-primary);
  }
}

.linkContent {
  display: inline-block;
  position: relative;
  padding: 8px 0;
  overflow: hidden;
}

.linkContent::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: var(--accent-primary);
  transform: translateX(-100%);
  transform-origin: 0 0;
  transition: transform 0.25s ease-out;
}

.link:hover .linkContent::after {
  transform: translateX(0);
}

.wave {
  display: block;
  width: 50px;
  height: 50px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('../../imgs/donda.png');
  border-radius: 50%;
  margin-right: 10px;
  border: 2px solid var(--accent-primary);
  box-sizing: border-box;
}

@media screen and (max-width: 500px) {
  .header {
    padding-bottom: 0;
  }

  .container {
    padding: 0 10px;
  }

  .inner {
    flex-direction: column;
    justify-content: center;
  }

  .nav {
    padding-left: 0;
  }

  .link {
    font-size: 16px;
  }
}

