.bubble {
  position: relative;
  min-width: 300px;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--accent-primary);
  color: white;
  border-radius: 20px;
  text-align: center;

  &:after {
    content: '';
    position: absolute;
    display: block;
    bottom: -36px;
    left: 70px;
    border-style: solid;
    border-width: 0px 40px 40px 0px;
    border-color: transparent var(--accent-primary) transparent transparent;
  }
}

@media screen and (max-width: 500px) {
  .bubble {
    width: 100%;
    min-width: 280px;

    &:after {
      bottom: -34px;
      border-width: 0px 35px 35px 0px;
    }
  }
}

@keyframes expand {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
