.title {
  max-width: 750px;
}

.desc {
  width: 100%;
  max-width: 750px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.img {
  height: 250px;
}