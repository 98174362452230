.caption {
  font-size: 18px;
  color: var(--text-color-primary);
  font-weight: 500;
}

@media screen and (max-width: 500px) {
  .caption {
    font-size: 14px;
  }
}