.root {
  position: relative;
  width: 100%;
  max-width: 600px;
  animation: fadeIn .3s ease-out 0s 1;
  animation-fill-mode: forwards;
  white-space: pre-line;
  opacity: 0;

  .copy {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}

.content {
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 40px 20px 45px 20px;
  line-height: 1.5;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--accent-primary);
}

.cat {
  position: absolute;
  top: -80px;
  left: 50%;
  width: 100px;
  height: 100px;
  background-image: url('../../imgs/moto-cat.png');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
} 