.button {
  min-width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  text-decoration: none;
  box-sizing: border-box;
  cursor: pointer;
}

.button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.4;
}

.button.fullWith {
  width: 100%;
}

.default {
  padding: 10px 20px;
  border-radius: 12px;
}

.default svg {
  margin-right: 11px;
}

.small {
  min-width: 40px;
  height: 40px;
  border-radius: 14px;
}
.large {
  font-size: 24px;
  border-radius: 14px;
  padding: 15px 30px;
}

.solid.primary {
  color: var(--text-color-primary);
  background-color: var(--second-color);
}

.solid.primary:hover {
  background-color: var(--accent-primary);
}