.footer {
  grid-row-start: 3;
  grid-row-end: 3;
  padding: 20px;
  background-color: rgba(255, 105, 180, 1);
  color: var(--text-color-primary);
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contactLink {
  display: inline-flex;
  color: var(--text-color-primary);
  align-items: flex-end;
  padding-right: 15px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  transition: color 0.25s ease-out;
  line-height: 1;
  outline: none;
}

.contactLink:hover {
  color: var(--second-color);
}

.copyright {
  font-size: 16px;
  font-weight: 500;
}

.contactIcon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  line-height: 1;
}

@media screen and (max-width: 500px) {
  .copyright {
    font-size: 12px;
  }
}