.title {
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--accent-primary);
  text-align: center;
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 26px;
  }
}
