.container {
  position: relative;
  width: 150px;
  height: 150px;

  &::after {
    content: '';
    position: absolute;
    top: 64px;
    left: 50px;
    width: 10px;
    height: 10px;
    background-color: #00eaff;
    border-radius: 50%;
    border-top-right-radius: 0;
    transform: rotate(-45deg);
    opacity: 0;
    transition: opacity .1s ease-out, transform 0.5s ease-out;
  }

  &.sad {
    &:after {
      opacity: 1;
      transform: translateY(3px) rotate(-45deg);
    }

    .mouth {
      top: 68px;
      border-top: 2px solid #000;
      border-bottom: none;
    }
  }
}

.img {
  width: 100%;
  height: 100%;
}

.eye {
  position: absolute;
  top: 53px;
  width: 7px;
  height: 7px;
  background-color: #000;
  border-radius: 50%;
  transition: tranfor .25s ease-out;

  &.right {
    right: 52px;
  }

  &.left {
    left: 50px;
  }
}

.mouth {
  position: absolute;
  top: 65px;
  left: calc(50% - 1px);
  width: 10px;
  height: 7px;
  border-bottom: 2px solid #000;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}