.root {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    border-radius: 10px;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    animation: glowing 20s linear infinite;
    background: linear-gradient(
      45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000
    );
    background-size: 400%;
    filter: blur(5px);
    transform: translateZ(0);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: #c0c0c0;
    border: 1px solid rgba(0, 0, 0, 0.1)
  }
}

.inner {
  width: 100%;
  height: 130px;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  &:hover {
    .btn {
      visibility: visible;
      opacity: 0.85;
      transform: translate(-50%, -50%);
    }
  }

  &.hidden {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
}

.img {
  width: 100%;
  max-width: 250px;
}

.btn {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  font-weight: 700;
  color: var(--accent-primary);
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  outline: none;
  opacity: 0;
  transform: translate(-50%, -50%);
  visibility: hidden;
  transition: opacity .2s ease-out;
  animation: show 2s ease-out 29s 1;
  cursor: pointer;
}

.textareaWrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.25s ease-out;

  &.visible {
    opacity: 1;
    z-index: 1;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }

  30%, 70% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
  }
} 