.root {
  width: 100%;
  position: relative;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    &.hidden {
      display: none;
      z-index: -1;
      visibility: hidden;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 110px;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}
.monitorWrap {
  position: relative;
  width: 320px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .dialog {
    position: absolute;
    bottom: calc(100% - 20px);
    left: 50%;
    transform: translateX(-50%);
  }
}

.monitor {
  width: 100%;
}

.mascot {
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btnWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.submitBtn {
  position: relative;
  padding-left: 55px;

  &:after {
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    left: 0;
    background-image: url('../../imgs/mouse.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transform: translateY(-50%);
  }
}

.bottom {
  min-width: 420px;
  min-height: 225px;
}

.response {
  margin-top: 55px;
}

@media screen and (max-width: 500px) {
  .bottom {
    width: calc(100% - 10px);
    max-width: 420px;
    min-width: auto;
  }

  .monitorWrap {
    width: 290px;
    height: 290px;
  }

  .btnWrap {
    margin-top: 20px;
  }
}
