.root {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: var(--primary-color);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px 40px 20px;
}

@media screen and (max-width: 500px) {
  .container {
    padding: 20px 15px 20px 15px;
  }
}